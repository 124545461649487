
import {
    ref, reactive, watch, defineComponent
} from 'vue';

export default defineComponent({
    props: {
        title: {
            type: String,
            required: true
        },
        content: {
            type: String,
            required: true
        },
        isShowLoadingDialog: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const isVisible = ref(false);

        watch(() => props.isShowLoadingDialog, () => {
            isVisible.value = props.isShowLoadingDialog;
        });
        function closeDialog() {
            emit('close');
        }

        return {
            isVisible,
            closeDialog
        };
    }
})

